import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Protoxies`}</strong>{` are members of `}<a parentName="p" {...{
        "href": "/The%20Argent",
        "title": "The Argent"
      }}>{`The Argent`}</a>{` that act as emissaries to other parts of `}<a parentName="p" {...{
        "href": "/Aljieudum",
        "title": "Aljieudum"
      }}>{`Aljieudum's`}</a>{` government and industry, ensuring that the doctrines of the Church are not being violated, and are often given authoritative power to enact legal and criminal justice as they see fit.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      